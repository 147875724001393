<template>
  <b-card v-if="render">
    <b-row class="mb-3">
      <div class="col-md-12 d-flex justify-content-end align-items-end">
        <b-button @click="confirmEmission" variant="btn btn-primary" :disabled="emissionRequest.status != 'pendente'">Confirmar Emissão</b-button>
        <b-button @click="showRejectModal" variant="btn btn-secondary ml-2" :disabled="emissionRequest.status != 'pendente'">Rejeitar</b-button>
      </div>
    </b-row>
    <b-row>
      <table class="table">
        <tbody>
          <tr>
            <td style="width: 250px"><strong>ID</strong></td>
            <td>{{ emissionRequest.id }}</td>
          </tr>
          <tr>
            <td><strong>Status</strong></td>
            <td><div v-html="statusBadge(emissionRequest.status)"></div></td>
          </tr>
          <tr v-if="emissionRequest.status != 'pendente'">
            <td><strong>Motivo</strong></td>
            <td>{{ emissionRequest.mensagem_status }}</td>
          </tr>
          <tr>
            <td><strong>Número Série</strong></td>
            <td>{{ emissionRequest.numero_serie }}</td>
          </tr>
          <tr>
            <td><strong>Número Emissão</strong></td>
            <td>{{ emissionRequest.numero_emissao }}</td>
          </tr>
          <tr>
            <td><strong>Valor nominal unitário</strong></td>
            <td>{{ moneyFormatDatatable(emissionRequest.valor_nominal_unitario) }}</td>
          </tr>
          <tr>
            <td><strong>Valor total integralizado</strong></td>
            <td>{{ moneyFormatDatatable(emissionRequest.valor_total_integralizado) }}</td>
          </tr>
          <tr>
            <td><strong>Solicitada em</strong></td>
            <td>{{ dateTimeToDateFormatter(emissionRequest.created_at) }}</td>
          </tr>
          <tr>
            <td><strong>Callback URL</strong></td>
            <td>{{ emissionRequest.callback_url }}</td>
          </tr>
  
          <tr>
            <td colspan="2"><h3>Debenturista</h3></td>
          </tr>
          <tr>
            <td><strong>Nome Fantasia</strong></td>
            <td>{{ emissionRequest.debenturista.nome_fantasia }}</td>
          </tr>
          <tr>
            <td><strong>Razão Social</strong></td>
            <td>{{ emissionRequest.debenturista.razao_social }}</td>
          </tr>
          <tr>
            <td><strong>CNPJ</strong></td>
            <td>{{ emissionRequest.debenturista.cnpj }}</td>
          </tr>
          <tr>
            <td valign="top"><strong>Contato</strong></td>
            <td>
              <strong>Nome:</strong> {{ emissionRequest.debenturista.contato.nome }}<br>
              <strong>E-mail:</strong> {{ emissionRequest.debenturista.contato.email }}<br>
              <strong>Telefone:</strong> {{ emissionRequest.debenturista.contato.telefone }}
            </td>
          </tr>
  
          <tr>
            <td colspan="2"><h3>Endereço</h3></td>
          </tr>
          <tr>
            <td><strong>Logradouro</strong></td>
            <td>{{ emissionRequest.debenturista.endereco.logradouro }}</td>
          </tr>
          <tr>
            <td><strong>Número</strong></td>
            <td>{{ emissionRequest.debenturista.endereco.numero }}</td>
          </tr>
          <tr>
            <td><strong>Complemento</strong></td>
            <td>{{ emissionRequest.debenturista.endereco.complemento }}</td>
          </tr>
          <tr>
            <td><strong>Bairro</strong></td>
            <td>{{ emissionRequest.debenturista.endereco.bairro }}</td>
          </tr>
          <tr>
            <td><strong>Cidade</strong></td>
            <td>{{ emissionRequest.debenturista.endereco.cidade }}</td>
          </tr>
          <tr>
            <td><strong>UF</strong></td>
            <td>{{ emissionRequest.debenturista.endereco.uf }}</td>
          </tr>
  
          <tr>
            <td colspan="2"><h3>Conta Série</h3></td>
          </tr>
          <tr>
            <td><strong>Banco</strong></td>
            <td>{{ emissionRequest.conta_serie.banco }}</td>
          </tr>
          <tr>
            <td><strong>Agencia</strong></td>
            <td>{{ emissionRequest.conta_serie.agencia }}</td>
          </tr>
          <tr>
            <td><strong>Conta</strong></td>
            <td>{{ emissionRequest.conta_serie.conta }}</td>
          </tr>
          <tr>
            <td><strong>Digito</strong></td>
            <td>{{ emissionRequest.conta_serie.digito }}</td>
          </tr>
  
          <tr>
            <td colspan="2"><h3>Representantes</h3></td>
          </tr>
  
          <tr v-for="(representante, i) in emissionRequest.representantes">
            <td valign="top"><strong>Representante {{ i + 1 }}</strong></td>
            <td>
              <strong>Nome:</strong> {{ representante.nome }}<br>
              <strong>CPF:</strong> {{ representante.cpf }}<br>
              <strong>E-mail:</strong> {{ representante.email }}<br>
              <strong>Telefone:</strong> {{ representante.telefone }}
            </td>
          </tr>
  
        </tbody>
      </table>
    </b-row>

    <b-modal
      id="reject-modal"
      ref="rejectModal"
      title="Confimar rejeição"
      @ok="confirmReject"
      @cancel="cancelReject"
      size="md"
      ok-title="Rejeitar Emissao"
      cancel-title="Não Rejeitar"
      centered
    >
      <p>Digite o motivo da rejeição:</p>
      <b-form-input v-model="statusMessage" placeholder="Seu texto aqui"></b-form-input>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BButtonGroup,
  BPagination,
  BTable,
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
import EmissionRequest from '@/services/emission_request'
import {
  dateTimeToDateFormatter,
  moneyFormatDatatable,
  dateFormatter
} from '@core/comp-functions/data_visualization/datatable'

export default {
  name: 'EmissionRequestShow',
  components: {
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BButtonGroup,
    BPagination,
    BTable,
    BDropdown,
    BDropdownItem,
    dateTimeToDateFormatter,
    moneyFormatDatatable,
    dateFormatter
  },
  data(){
    return {
      render: false,
      emissionRequest: {},
      statusMessage: ''
    }
  }, 
  async mounted(){
    await this.getEmissionRequest()
  },
  methods: {
    async getEmissionRequest(){
      const response = await EmissionRequest.get(this.$route.params.id)
      this.emissionRequest = response.data
      this.render = true
    },
    moneyFormatDatatable,
    dateTimeToDateFormatter,
    dateFormatter,
    async confirmEmission(){
      this.$bvModal.msgBoxConfirm('Deseja confirmar a emissão?', {
        title: 'Confirmação',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'success',
        okTitle: 'Confirmar Emissao',
        cancelTitle: 'Não Confirmar',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (confirm) => {
        if (!confirm) return
        try{
          await EmissionRequest.confirm(this.$route.params.id)
          this.getEmissionRequest()
          this.$swal({
            title: 'Sucesso!',
            text: 'Emissão confirmada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok',
          })
        }catch(e){
          this.$swal({
            title: 'Erro!',
            html: e.response.map (error => error.message).join(';<br>'),
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        }
      })
      .catch((e) => {
        this.$swal({
          title: 'Erro!',
          text: e.response,
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      })
    },
    showRejectModal() {
      this.$refs.rejectModal.show(); // Exibe o modal
    },
    async confirmReject() {
      let payload = {
        id: this.$route.params.id,
        motivo: this.statusMessage
      }
      try{
        await EmissionRequest.reject(payload)
        this.getEmissionRequest()
        this.$swal({
          title: 'Sucesso!',
          text: 'Solicitação rejeitada com sucesso',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
      }catch(e){
        this.$swal({
          title: 'Erro!',
          html: e.response.map (error => error.message).join(';<br>'),
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },
    cancelReject() {
      console.log('Ação cancelada!');
    },
    statusBadge(status) {
      const variant = {
        pendente: 'light text-dark',
        emitida: 'success',
        rejeitada: 'danger',
      }[status]
      const text = {
        pendente: 'Pendente',
        emitida: 'Emitida',
        rejeitada: 'Rejeitada',
      }[status]

      return `<span class="badge badge-light-${variant} border-${variant} badge-pill">${text}</span>`
    },
  },
}
</script>